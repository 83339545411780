// ref: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-router-config/react-router-config-tests.tsx

import type { RouteConfig } from 'react-router-config';
import notFoundRoutes from 'routes/NotFound';

import App from './layout';

import homeRoutes from './Home';
import taipeiffWorkshopRoutes from './TaipeiffWorkshop';
import taipeiffActorArchiveRoutes from './TaipeiffActorArchive';
import acmePreorderRoutes from './AcmePreorder';
import templeExhibitionRoutes from './TempleExhibition';
import templeExhibitionMatterportRoutes from './TempleExhibitionMatterport';
import mangaChatRoutes from './MangaChat';
import hengchunGuideRoutes from './HengchunGuide';
import hengchunGuideV2Routes from './HengchunGuideV2';
import ChuankanngRoutes from './Chuankanng';
import RickyLiuRoutes from './RickyLiu';

const routes: RouteConfig[] = [
    {
        component: App,
        routes: [
            ...homeRoutes,
            ...taipeiffWorkshopRoutes,
            ...taipeiffActorArchiveRoutes,
            ...acmePreorderRoutes,
            ...templeExhibitionRoutes,
            ...templeExhibitionMatterportRoutes,
            ...mangaChatRoutes,
            ...hengchunGuideRoutes,
            ...hengchunGuideV2Routes,
            ...ChuankanngRoutes,
            ...RickyLiuRoutes,
            ...notFoundRoutes,
        ],
    },
];

export default routes;
